<template>
  <div id="app" class="relative">
    <div class="flex h-screen justify-center items-center" v-if="loading">
      <div>
        <img
          src="/splash.png"
          class="h-[30px] md:h-[50px] w-[33px] md:w-[55px] animate-pulse"
          alt="splash screen"
        />
      </div>
    </div>
    <div v-else>
      <header>
        <app-nav @openDrawerNav="openDrawer"></app-nav>
      </header>

      <div v-if="drawer">
        <nav-drawer @close-button="closeDrawer()"></nav-drawer>
      </div>

      <main class="min-h-screen">
        <router-view />
      </main>

      <footer>
        <footer-section></footer-section>
      </footer>

      <section class="fixed z-50 bottom-4 right-4" @click="openDesk">
        <div class="relative cursor-pointer">
          <img
            src="/icons/whatsapp.svg "
            class="w-[50px] h-[50px] 2xl:w-[80px] 2xl:h-[80px]"
            title="Chat with us"
            alt="whatsapp"
          />
        </div>
      </section>

      <section
        class="fixed z-50 right-10 md:right-14 bottom-20 2xl:bottom-32"
        v-if="contactDesk"
      >
        <div class="md:h-[300px] md:w-[400px] bg-white rounded-lg shadow-sm">
          <div class="py-2 px-5 bg-primary-shades rounded-t-lg">
            <div @click="closeDesk" class="flex justify-end cursor-pointer">
              <p class="text-2xl text-white">x</p>
            </div>
            <div class="py-2">
              <h3 class="text-white text-xl 2xl:text-2xl font-bold">HELLO!</h3>
              <p class="text-white">
                Click our contact below to chat on WhatsApp
              </p>
            </div>
          </div>

          <div class="p-10">
            <a
              href="https://wa.me/2349087700608?text=Hello!%20I'm%20texting%20from%20the%20website,%20I%20want%20to%20enquire%20about%20Reddo%20Spaces"
              target="_blank"
              rel="noopener noreferrer"
              class="flex gap-5 items-center p-3 hover:bg-accent-tint rounded-md"
            >
              <span class="w-[50px] h-[50px] rounded-full">
                <img
                  src="/images/office.jpeg"
                  class="rounded-full w-[50px] h-[50px]"
                  alt="help desk"
                />
              </span>
              <span>
                <h4 class="capitalize text-accent-shades text-sm">
                  front office
                </h4>
                <p class="text-xl font-bold">Amos</p>
              </span>
            </a>
          </div>
          <div class="border-b-[0.5px] border-accent-tint"></div>
          <p class="px-10 pt-5">Thanks for contacting Reddo workspace.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import AppNav from "./components/AppNav.vue";
import FooterSection from "./components/FooterSection.vue";
import NavDrawer from "./components/NavDrawer.vue";
export default {
  name: "App",
  components: { FooterSection, AppNav, NavDrawer },

  data() {
    return {
      color: "",
      drawer: false,
      loading: false,
      contactDesk: false,
    };
  },

  created() {
    this.getSiteOpen();
  },

  methods: {
    getSiteOpen() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 5000);
    },

    onScroll() {
      if (document.documentElement.scrollTop > 20) {
        this.color = "#4fdddd";
      } else {
        this.color = "#fff";
      }
    },

    openDrawer() {
      this.drawer = !this.drawer;
    },
    closeDrawer() {
      this.drawer = false;
    },

    openDesk() {
      this.contactDesk = !this.contactDesk;
    },
    closeDesk() {
      this.contactDesk = false;
    },
  },
};
</script>
